{
  "transactions": [
    {
      "id": "ce42454e-09c0-47d4-b24a-9f528e27e0b3",
      "raccount": "f87a4199-8fb2-42e8-854c-d30dc8dd58e5",
      "description": "bi-weekly paycheck",
      "category": "paycheck",
      "type": "income",
      "start": "YYYY-01-01",
      "rtype": "day",
      "cycle": 14,
      "value": 1500
    },
    {
      "id": "bf757794-5e33-406a-a510-c33bf9814755",
      "raccount": "f87a4199-8fb2-42e8-854c-d30dc8dd58e5",
      "description": "monthly rent",
      "category": "rent",
      "type": "expense",
      "start": "YYYY-01-01",
      "rtype": "day of month",
      "cycle": 1,
      "value": 700
    }
  ],
  "accounts": [
    {
      "id": "f87a4199-8fb2-42e8-854c-d30dc8dd58e5",
      "name": "account",
      "starting": 400,
      "interest": {
        "amount": 2000,
        "scale": 2
      },
      "vehicle": "operating",
      "visible": true
    }
  ]
}
