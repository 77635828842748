import { str, StrOutput } from "./str.js";
import { num, NumOutput } from "./num.js";
import { table, TableOutput } from "./table.js";
import { any, AnyOutput } from "./any.js";
import { obj, ObjOutput } from "./obj.js";
import {
  defaultLoader,
  defaultLoaderItem,
  LoaderOutput,
  loaders,
} from "./loaders.js";

export const slice = {
  str,
  num,
  table,
  any,
  obj,
  loaders,
  /**
   * @deprecated Use `slice.loaders` instead
   */
  loader: loaders,
};
export { defaultLoader, defaultLoaderItem };
export type {
  AnyOutput,
  LoaderOutput,
  NumOutput,
  ObjOutput,
  StrOutput,
  TableOutput,
};
